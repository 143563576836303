<template>
  <ion-page>
    <ion-header>
      <MainToolbar :title="i18n.$t('quick-analysis.title')" />
    </ion-header>
    <ion-content v-if="slidesActive" :fullscreen="true">
      <ion-slides pager>

        <ion-slide>
          <div class="slide">
            <img src="/assets/eye_preview.png"/>
            <h2>Welcome</h2>
            <p>The <b>ionic conference app</b> is a practical preview of the ionic framework in action, and a demonstration of proper code use.</p>
          </div>
        </ion-slide>

        <ion-slide>
          <img src="/assets/eye_preview.png"/>
          <h2>What is Ionic?</h2>
          <p><b>Ionic Framework</b> is an open source SDK that enables developers to build high quality mobile apps with web technologies like HTML, CSS, and JavaScript.</p>
        </ion-slide>

        <ion-slide>
          <!-- <img src="/assets/eye_preview.png"/> -->
          <h2>What is Appflow?</h2>
          <p><b>Appflow</b> is a powerful set of services and features built on top of Ionic Framework that brings a totally new level of app development agility to mobile dev teams.</p>
        </ion-slide>

        <ion-slide>
          <!-- <img src="/assets/eye_preview.png"/> -->
          <h2>Ready to Play?</h2>
          <ion-button fill="clear">Continue <ion-icon slot="end" name="arrow-forward"></ion-icon></ion-button>
        </ion-slide>

      </ion-slides>
    </ion-content>
    <ion-content v-else :fullscreen="true">
      <ion-card button @click="startAnalysis('eye')">
        <div class="preview-image">
          <img src="/assets/eye_preview.png" />
        </div>
        <ion-card-header>
          <ion-card-subtitle>Bildanalyse</ion-card-subtitle>
          <ion-card-title>Augenuntersuchung</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          Untersuchung auf 22 häufige Augenkrankheiten beim Pferd, die ohne spezielles Equipment sichtbar sein können
        </ion-card-content>
      </ion-card>

      <!--<ion-card button @click="startAnalysis('lameness')">
        <div class="preview-image">
          <img src="/assets/eye_preview.png" />
        </div>
        <ion-card-header>
          <ion-card-subtitle>Videoanalyse</ion-card-subtitle>
          <ion-card-title>Lahmheitsuntersuchung</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          Untersuchung auf Lahmheit im Gangmuster des Pferdes
        </ion-card-content>
      </ion-card>-->
    </ion-content>
  </ion-page>
  
</template>

<script>
import { IonPage, IonHeader, IonContent, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonSlides, IonSlide, IonIcon, IonButton } from '@ionic/vue';

import MainToolbar from '@/components/MainToolbar.vue';

import { openCameraModal, default as modalComponent } from '@/components/CameraModal.vue';

import { ref } from 'vue';

import { useI18n } from "@/utils/i18n";

export default  {
  name: 'QuickAnalysis',
  components: { IonHeader, IonContent, IonPage, MainToolbar, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonSlides, IonSlide, IonIcon, IonButton },
  setup() {
    const i18n = useI18n();

    const slidesActive = ref(false);

    const startAnalysis = async function(type) {
      switch (type) {
        case 'eye':
          openCameraModal(modalComponent, {
            stillFrame: true,
            captureType: 'eye',
            flashSuggested: true,
            aspectRatio: 1
          }).then((capturedImage) => {
            if (capturedImage.data != null) {
              console.log(capturedImage.data); //TODO Do analysis here, do not forget to release ObjecctURL handle again
            }
          });
          break;

        case 'lameness':
          openCameraModal(modalComponent, {
            requiresVideo: true,
            captureType: 'lameness',
            flashSuggested: true, //TODO Test if flash button is also wanting attention and then disable flash suggestion for lameness
          }).then((capturedVideo) => {
            if (capturedVideo.data != null) {
              console.log(capturedVideo.data); //TODO Do analysis here, do not forget to release ObjecctURL handle again
            }
          });
          break;
      
        default:
          return;
      }
      //slidesActive.value = true;
    }

    return { i18n, slidesActive, startAnalysis };
  }
}
</script>

<style scoped>
.preview-image {
  max-height: 200px;
  width: 100%;
  overflow: hidden;
}

ion-slides {
  height: 100%;
}

.swiper-slide {
  display: block;
}

.swiper-slide h2 {
  margin-top: 2.8rem;
}

.swiper-slide img {
  max-height: 50%;
  max-width: 80%;
  margin: 60px 0 40px;
  pointer-events: none;
}
</style>
